import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from './ai.stc'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from "prop-types"
import {
    useStaticQuery,
    graphql,
    navigate
    // Link
}
    from "gatsby"
import Button from "../../../components/shared/button";

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    const servicesQueryData = useStaticQuery(graphql`
        query ServicesAiDataQuery {
            servicesaidataJson(jsonId: {eq: "ai-content"}) {
                aiServices {
                    title
                    classId
                    link
                    description
                    tableHeader
                    tableData
                    image {
                        childImageSharp {
                            fluid(maxWidth: 960, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    } 
                }
            }
        }    
    `)
    // const serviceData = servicesQueryData.servicesaidataJson;
    // const aiServices = serviceData.aiServices;

    // const aiContent = aiServices.map((aiService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={aiService.link}>{aiService.title}</a>
    //     </div>
    // );

    // const content = aiServices.map((aiService, index) =>
    //     <div className="row services" key={index} id={aiService.classId}>
    //         <div className="col-2 offset-1">
    //             <SectionInner style={{ paddingRight: "10vh" }}>
    //                 <SectionTitleWrap>
    //                     <Heading {...HeadingStyleServies}>{aiService.title}</Heading>
    //                     {aiService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
    //                 </SectionTitleWrap>
    //             </SectionInner>
    //         </div>
    //         <div className="col-2">
    //             <SectionInner>
    //                 {aiService.image ?
    //                     <Fade right>
    //                         <img src={aiService.image.childImageSharp.fluid.src} alt={aiService.title} />
    //                     </Fade>
    //                     :
    //                     <Fade right>
    //                         <SectionTitleWrap>
    //                             {aiService.tableHeader && <Heading {...SubHeadingStyle}>{aiService.tableHeader}</Heading>}
    //                             <div className="row">
    //                                 {aiService.tableData.map((textData, i) =>
    //                                     <div className="col-1" key={`story-text-${i}`}>
    //                                         <Text {...Paragraph}>{textData}</Text>
    //                                     </div>)
    //                                 }
    //                             </div>
    //                         </SectionTitleWrap>
    //                     </Fade>
    //                 }

    //             </SectionInner>
    //         </div>
    //     </div>
    // );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyle}>Machine Learning and Artificial Intelligence</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage style={{ marginTop: 50 }} src={"../../../../src/data/images/AI-Test.jpg"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="transform-orgainisation" {...HeadingStyleServies}>Transform your Organisation.</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h4>
                            With autonomous decision making across every aspect of your business operations.
                        </h4>

                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap >
                        <Heading {...HeadingStyleServies}>Understand How Your Business Can Benefit</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>From detecting images to voice and facial recognition - predicting supply chain failures, stock levels - making sales, automating tasks to customer support and much more. AI is pervasive and available to any organisation today.
                        </p>
                        <p style={{ width: "70%", fontSize: 20 }}>We discuss how to solve AI and ML problems. Talk to us about them today.</p>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: 'darkorange', color: 'white' }}
                        >
                            See How <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="rocket-ping" src={"../../../../src/data/images/A.jpeg"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading style={{ marginTop: 30 }} {...HeadingStyleServies}>Artificial Intelligence</Heading>
                        <br></br>
                        <Text {...Paragraph}>
                            Artificial intelligence, is intelligence demonstrated by machines, unlike the natural intelligence displayed by humans and animals.
                        </Text>
                        <Text {...Paragraph}>
                            Make lifelike predictions, comprehend speech, analyse images, traverse gigantic datasets and find pertinent information.
                        </Text>
                        <Text {...Paragraph}>
                            We can help you build models that can be trained and developed to act much like the human brain, only and probably much better!
                        </Text>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                        >
                            See How <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="cars-ping" src={"../../../../src/data/images/ML-Cars.png"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyleServies}>Machine Learning</Heading>
                        <br></br>
                        <Text {...Paragraph}>
                            Machine learning is an application of artificial intelligence (AI) that provides systems the ability to automatically learn and improve from experience without being explicitly programmed.
                        </Text>
                        <Text {...Paragraph}>
                            Machine learning focuses on the development of computer programs that can access data and use it learn for themselves.
                        </Text>
                        <Text {...Paragraph}>
                            Incorporate Machine Learning Models such as Naive Bayes, Random Forest and others into your applications.
                        </Text>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: 'green', color: 'white' }}
                        >
                            See How <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="site-ping" src={"../../../../src/data/images/ML-Site.jpeg"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            {/*{content}*/}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    aiServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

