import styled from 'styled-components';
import {device} from '../../../theme'

export const AboutSectionWrap = styled.div `
    padding-top: 80px;
    background-color: black;

    .section-pt-md{
        padding-top: 70px;
    }
    .col-1.offset-custom{
        margin-left: 5vw;
    }
    .services{
        padding-top: 15vh;
        padding-bottom: 15vw;
        background-color: white;
        align-items: center;
    }
    .service-top-section{
        margin-bottom: 20vh;
    }
`;

export const SectionInnerLeft = styled.div `
    width: 50%;
    padding-left: 10vw;
    padding-right: 2vw;
    padding-bottom: 2vw;

    @media ${device.largest}{
        margin-top: 55px;
    }
    
    @media ${device.small}{
        width: 100%;
    }
`;

export const SectionInnerRight = styled.div `
    width: 50%;
    padding-right: 10vw;
    @media ${device.largest}{
        .rocket-ping {
           max-width: 600px;
           max-height: auto;
        }
        .cars-ping {
            max-width: 600px;
            max-height: auto;
        }
        .site-ping {
            max-width: 600px;
            max-height: auto;
        }
    }
    @media ${device.large}{
        .rocket-ping {
            margin-bottom: 16vw;
        }
        .cars-ping {
            margin-bottom: 13vw;
        }
        .site-ping {
            margin-bottom: 12vw;
        }
    }
    
    @media ${device.medium}{
        .rocket-ping {
            margin-bottom: 42vw;
        }
        .cars-ping {
            margin-bottom: 38vw;
        }
        .site-ping {
            margin-bottom: 36vw;
        }
    }
    @media ${device.small}{
        width: 100%;
        padding-left: 10vw;
        .rocket-ping {
            margin-top: 5vw;
        }
        .cars-ping {
            margin-top: 5vw;
        }
        .site-ping {
            margin-top: 5vw;
        }
    }
`;